import type { AppProps } from "next/app";
import * as Sentry from "@sentry/nextjs";
import { ApolloProvider } from "@apollo/client";
import { useApollo } from "~/server/apollo";
import "react-spring-bottom-sheet/dist/style.css";

export default function App({ Component, pageProps }: AppProps) {
  const client = useApollo(pageProps, false);
  return (
    <Sentry.ErrorBoundary>
      <ApolloProvider client={client}>
        <Component {...pageProps} />
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}
