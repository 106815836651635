import { useMemo } from "react";
import type { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { isEqual } from "@react-hookz/deep-equal";
import * as Sentry from "@sentry/nextjs";
import { SentryLink } from "apollo-link-sentry";
import merge from "deepmerge";
import type { GetServerSidePropsContext } from "next";
import { API_URL } from "~/config/env";

export const APOLLO_STATE_PROPERTY_NAME = "__APOLLO_STATE__";
export const EXCEPTIONS_HANDLED_BY_ERROR_LINK = [
  "Session mismatch",
  "Unrecognized session!",
  "Signature has expired",
  "Error decoding signature",
];

export type PageProps = Record<string, any>;

export interface ServerSideProps {
  props: Record<string, any>;
}

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

const ApolloSentryLink = new SentryLink({
  uri: API_URL,
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
    includeFetchResult: false,
    transform: breadcrumb => {
      const variables = breadcrumb.data.variables;
      if (variables && "password" in variables) {
        delete breadcrumb.data.variables;
      }
      return breadcrumb;
    },
  },
});

export const createApolloClient = (ctx?: GetServerSidePropsContext) => {
  const errorLink = onError(({ graphQLErrors, operation }) => {
    if (graphQLErrors && graphQLErrors.length > 0) {
      graphQLErrors.forEach(err => {
        console.error(err);
        Sentry.captureException(err);
      });
    }
  });

  const httpLink = new HttpLink({
    uri: API_URL,
    credentials: "same-origin",
  });

  const authLink = setContext(async (value, { headers }) => {
    const authorization = "";

    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([ApolloSentryLink, errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
};

interface InitializeApolloParams {
  ctx?: GetServerSidePropsContext;
  initialState?: NormalizedCacheObject;
  forceRecreate?: boolean;
}

export function initializeApollo({
  initialState,
  ctx,
  forceRecreate,
}: InitializeApolloParams) {
  const client = forceRecreate
    ? createApolloClient(ctx)
    : apolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // Merge the existing cache into data passed from
    // getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s))),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") {
    return client;
  }

  // Create the Apollo Client once in the client
  if (!apolloClient || forceRecreate) {
    apolloClient = client;
  }

  return client;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: ServerSideProps,
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROPERTY_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps: PageProps, forceRecreate?: boolean) {
  const state = pageProps[APOLLO_STATE_PROPERTY_NAME];
  const store = useMemo(() => {
    return initializeApollo({ initialState: state, forceRecreate });
  }, [state, forceRecreate]);

  return store;
}
